import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { parseDate } from "helpers";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { calendarActions } from "store";
import "./Calendar.css"
import EventDialog from "./EventDialog";
import { useDrop } from "react-dnd";

export function CalendarWidget({ sx }) {
  const { t, i18n } = useTranslation();
  const { user } = useSelector(x => x.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());

  const { calendar } = useSelector(store => store);
  const { clientId } = useParams();

  const calendarRef = useRef(null);

  const events = calendar.map(event => {
    return {
      id: event.id,
      title: event.title,
      start: parseDate(event.dateFrom),
      end: parseDate(event.dateTo)
    }
  })

  useEffect(() => {
    dispatch(calendarActions.all(clientId))
  }, [dispatch, clientId]);


  const handlePrevDay = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setCurrentDate(calendarApi.getDate());
  };

  const handleNextDay = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setCurrentDate(calendarApi.getDate());
  };

  const handleSelect = (info) => {
    const { start, end } = info;
    setEvent({ dateFrom: start, dateTo: end, title: "", clientId });
    setOpen(true)
  }

  const handleEventClick = ({ event }) => {
    const { title, start, end, id } = event;
    setEvent({ dateFrom: start, dateTo: end, title, id });
    setOpen(true)
  }

  const handleEventChange = ({ event }) => {
    const { title, start, end, id } = event;
    dispatch(calendarActions.create({ dateFrom: start, dateTo: end, title, id }))
  }

  const formatDate = (date) => {
    const locale = i18n.language === 'ru' ? 'ru-RU' : 'uk-UK';
    const options = { weekday: 'short', day: 'numeric', month: 'long' };
    return new Intl.DateTimeFormat(locale, options).format(date);
  };

  const handleDrop = (info) => {
    const dateFrom = info.date;
    const dateTo = new Date(dateFrom.getTime() + (60 * 60 * 1000));
    const apartmentId = info.draggedEl.getAttribute('data-id');
    const title = info.draggedEl.getAttribute('data-title');

    dispatch(calendarActions.create({ dateFrom, dateTo, apartmentId, title, clientId }));
  }

  const [{didDrop}, drop] = useDrop(() => ({
    accept: 'apartment',
    drop(item) {
    },
  }))

  return <Grid
    ref={drop}
    className="calendar widget"
    item
    xs={3}
    sx={{
      backgroundColor: "newGrey.veryLight",
      borderRadius: "4px",
      boxShadow: "0px 0px 10px 2px rgba(1, 0, 34, 0.05)",
      p: 2.5
    }}
  >
    <Typography variant="h3" sx={{ textAlign: "center" }}>{t('calendar')}</Typography>
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
      <IconButton onClick={handlePrevDay} sx={{
        backgroundColor: "unset",
        '&:hover': { backgroundColor: "unset" }
      }}><ArrowLeftIcon /></IconButton>
      <Box
        sx={{ p: "6px", borderRadius: "30px", backgroundColor: "newGrey.medium2", width: "60%", textAlign: "center" }}>
        <Typography variant="text" sx={{ color: "dark", fontSize: "14px" }}>{formatDate(currentDate)}</Typography>
      </Box>
      <IconButton onClick={handleNextDay} sx={{
        backgroundColor: "unset",
        '&:hover': { backgroundColor: "unset" }
      }}><ArrowRightIcon /></IconButton>
    </Box>
    <StyledCalendar>
      <FullCalendar
        contentHeight="100"
        droppable={user.isCrmUser}
        drop={handleDrop}
        editable={user.isCrmUser}
        events={events}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        eventDrop={handleEventChange}
        eventResize={handleEventChange}
        initialView='timeGridDay'
        height="100%"
        headerToolbar={false}
        locale={i18n.language === 'ru' ? 'ru' : 'uk'}
        plugins={[timeGridPlugin, interactionPlugin]}
        ref={calendarRef}
        select={handleSelect}
        selectable={user.isCrmUser}
        slotMinTime="07:00:00"
        slotLabelFormat={{
          hour: 'numeric',
          minute: 'numeric',
        }}
        weekends={true}
      />
    </StyledCalendar>
    {open && <EventDialog open={open} event={event} handleClose={() => setOpen(false)} />}
  </Grid>
}

function renderEventContent(eventInfo) {
  return (
    <Box sx={
      {
        color: "text.white",
        display: "flex",
        fontSize: "14px",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        lineHeight: "22px",
        overflow: "hidden"
      }}>
      <Box sx={{fontWeight: 500}}>{eventInfo.timeText}</Box>
      <Box>{eventInfo.event.title}</Box>
    </Box>
  )
}

export const StyledCalendar = styled(Box)(({ theme }) => ({
  height: "calc(100% - 60px)",
  paddingTop: "10px",
  '& thead': {
    display: 'none'
  },
  '& td': {
    border: 'none',
  },
  '& .fc .fc-timegrid-col': {
    background: "transparent",
    backgroundColor: "transparent"
  },
  '& .fc .fc-cell-shaded': {
    background: "transparent",
    backgroundColor: "transparent"
  },
  '& .fc .fc-col-header ': {
    background: "transparent",
    backgroundColor: "transparent"
  },
  '& .fc-scrollgrid': {
    border: 'none',
  },
  '& .fc-scrollgrid-section > td': {
    border: 'none',
  },
  '& .fc-timegrid-slot-lane': {
    borderTop: '2px solid ' + theme.palette.newGrey.light,
  },
  '& .fc-timegrid-slot-minor': {
    borderTopStyle: "none"
  },
  '& .fc-timegrid-slot-label': {
    fontSize: "14px",
    width: "100px",
    color: theme.palette.black,
    '& .fc-timegrid-slot-label-cushion': {
      position: "relative",
      top: "-12px"
    }
  },
  '& [data-time="07:00:00"]': {
    display: "none",
  },
  '& .fc-event': {
    border: "none",
    boxShadow: "none"
  }
}));