import { Box, Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

export function FixedPlusButton(status) {
  const { t } = useTranslation(["translation", "form"]);
  const [hover, setHover] = useState(false);

  const handleScroll = (event) => {
    const yPosition = Math.ceil(document.querySelector('#main-container').scrollTop);
    const minPositionForShow = 100;
    const buttonEl = document.querySelector('.fixed-plus');

    if (yPosition > minPositionForShow) {
      buttonEl.style.opacity = 1;
    } else {
      buttonEl.style.opacity = 0;
    }
  };

  useEffect(() => {
    document.querySelector('#main-container').addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onClickHandler = (event) => {
    event.preventDefault();
    const buttonEl = document.querySelector('.new-column .add-button');
    buttonEl.click();
  }

  return <Box
    className={'fixed-plus'}
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}
    sx={{
      display: "block",
      position: "fixed",
      bottom: {xs: "84px", xl: "106px"},
      right: {xs: "32px", xl: "40px"},
      opacity: 0,
      transition: "all 0.2s ease-out",
      zIndex: 100,
    }}
  >
    {hover ? (
      <Button
        onClick={onClickHandler}
        variant="contained"
        color="primary"
        startIcon={<AddOutlinedIcon />}
        sx={{
          borderRadius:  { xs: "19px", xl: "24px" },
          color: "text.white",
          height:  { xs: "38px", xl: "48px" },
          '&:hover': {
            backgroundColor: "primary.main"
          }
        }}
      >
        {t('newDepartment')}
      </Button>
    ) : (
    <IconButton
      sx={{
        width: { xs: "38px", xl: "48px" },
        height: { xs: "38px", xl: "48px" },
        backgroundColor: "primary.main",
        color: "text.white"
      }}
    >
      <AddOutlinedIcon fontSize={"inherit"} />
    </IconButton>)}
  </Box>
}