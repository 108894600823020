import ApiDefault from "./ApiDefault";

const PATH = 'crm/agent';

export default class Agent extends ApiDefault {
  async create(data) {
    return this.post(PATH, data);
  }

  async update(data) {
    return this.patch(PATH + `/${data.id}`, data);
  }

  async free() {
    return this.get(PATH + "/free");
  }

  async all() {
    return this.get(PATH);
  }

  async upload(id, file, onUploadProgress) {
    let formData = new FormData();

    formData.append("avatarPhoto", file);
    id && formData.append("id", id);

    return this.post(PATH + "/avatar", formData, onUploadProgress);
  }

  async createAgency(name) {
    return this.post(PATH + "/agency", { name });
  }

  async deleteAgency() {
    return this.remove(PATH + "/agency");
  }

  async joinAgency(email) {
    return this.post(PATH + "/agency/join", { email });
  }

  async leaveAgency() {
    return this.put(PATH + "/agency/leave");
  }

  async leaveAgencyHead(id) {
    return this.put(PATH + "/head", { id });
  }

  async deleteAgencyUser(id) {
    return this.remove(PATH + '/' + id);
  }
}