import { createSlice } from "@reduxjs/toolkit";

const name = "message";
const initialState = { type: "info", text: "" };
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const messageActions = { ...slice.actions };
export const messageReducer = slice.reducer;

function createReducers() {
  return {
    addError, addSuccess, addInfo, addWarning, clear
  };

  function addError(state, { payload }) {
    state.type = "error";
    state.text = payload;

    return state;
  }

  function addSuccess(state, { payload }) {
    state.type = "success";
    state.text = payload;

    return state;
  }

  function addInfo(state, { payload }) {
    state.type = "info";
    state.text = payload;

    return state;
  }

  function addWarning(state, { payload }) {
    state.type = "warning";
    state.text = payload;

    return state;
  }

  function clear() {
    return initialState;
  }
}

