import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from 'react';
import { calendarApi } from "helpers";
import { useDispatch } from "react-redux";
import { calendarActions } from "store";

export const SubmitResetGroupCustom = ({
  addLabel,
  cancelLabel = null,
  disabled,
  handleClick,
  handleClose,
  loading,
  formik
}) => {
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch();

  const deleteCalendarItem = () => {
    calendarApi.delete(formik.values.id).then((result) => {
      dispatch(calendarActions.all()).then(() => {

        handleClose();
      });
    });
  }

  return <div>
    <LoadingButton endIcon={<></>} color="primary" disabled={disabled} loading={loading} onClick={handleClick} sx={{
        display: 'block',
        margin: '0px auto',
        marginTop:"50px",
        width: '100%',
        maxWidth: '400px',
      }}
      type="submit"
      variant="contained" >
      {addLabel}
    </LoadingButton>

    <Button color="primary"
          disabled={disabled} loading={loading} 
          type="button"
          sx={{
            display: 'block',
            margin: '0px auto',
            mt: 2,
            width: '100%',
            maxWidth: '400px',
            color: (theme) => theme.palette.dark
          }}

          onClick={() => {deleteCalendarItem();}}
        >
          <span className="customButtonColor">{t('delete')}</span>
        </Button>

    <Button disabled={loading} type="reset" sx={{
        display: 'block',
        margin: '0px auto',
        mt: 2,
        width: '100%',
        maxWidth: '400px',
        color: (theme) => theme.palette.dark
      }}
      onClick={handleClose} >
      {cancelLabel !== null ? cancelLabel : t('cancelTitle')}
    </Button>
  </div>
}