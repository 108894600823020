import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box, Button, FormHelperText, LinearProgress, Typography } from "@mui/material";
import { Avatar } from "components";
import { imagesPath } from "config";
import React from 'react';
import { useTranslation } from "react-i18next";

export function AvatarField({ avatar, avatarFile, entity, handleUpload, message, progress }) {
  const { t } = useTranslation(["translation", "form"]);

  return <>
    <Typography variant="h4" mt={3.75}>
      {t('photo')}
    </Typography>
    <Box sx={{ alignItems: "center", display: "flex", mt: 2.5 }}>
      <Avatar sx={{ height: 39, mr: 3, width: 39, color: "#826BFF" }}
                 src={entity?.avatar ? imagesPath + entity.avatar.smallImage : avatar} />

      {avatarFile ?
        <Box sx={{ width: 276, height: 32, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Typography sx={{
            maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"
          }}>{avatarFile.name}</Typography>
          <LinearProgress variant="determinate" value={progress} color="success" />
        </Box> : <Button
          color="text"
          component="label"
          startIcon={<FileDownloadOutlinedIcon />}
          sx={{ fontWeight: "bold" }}
          variant="text"
        >
          {t('uploadPhoto')}
          <input hidden accept="image/*" multiple type="file" name="avatarPhoto" onChange={(e) => handleUpload(e)} />
        </Button>}
      {message && <FormHelperText error={true}>{message}</FormHelperText>}
    </Box>
  </>
}