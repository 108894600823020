import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { parseJwt, userApi } from 'helpers';

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

function createInitialState() {
  return {
    user: JSON.parse(localStorage.getItem('user')),
    register: JSON.parse(localStorage.getItem('register')),
    error: null,
    personalInfo: null,
  }
}

function createReducers() {
  return {
    logout, updateAvatar, clear
  };

  function logout(state) {
    state.user = null;
    localStorage.removeItem('user');
    document.location.href = '/login';
  }

  function clear(state) {
    state.user = null;
    localStorage.removeItem('user');
  }

  function updateAvatar(state, { payload }) {
    state.personalInfo.avatar = payload;

    return state;
  }
}

function createExtraActions() {
  return {
    login: login(),
    register: register(),
    getPersonalInfo: getPersonalInfo(),
    update: update(),
  };

  function login() {
    return createAsyncThunk(`${name}/login`, async ({ email, password }) => await userApi.login({ email, password }));
  }

  function register() {
    return createAsyncThunk(`${name}/register`, async (data) => await userApi.register(data));
  }

  function getPersonalInfo() {
    return createAsyncThunk(`${name}/getPersonalInfo`, async (data) => await userApi.getPersonalInfo(data));
  }

  function update() {
    return createAsyncThunk(`${name}/update`, async (data) => await userApi.update(data));
  }
}

function createExtraReducers() {
  return {
    ...login(), ...register(), ...getPersonalInfo(), ...update()
  };

  function login() {
    const { pending, fulfilled, rejected } = extraActions.login;
    return {
      [pending]: (state) => {
        state.error = null;
      }, [fulfilled]: (state, action) => {
        let user = action.payload;
        const userPayload = parseJwt(user.token);
        const {
          id,
          avatar,
          email,
          lastName,
          firstName,
          middleName,
          isAgent,
          isDirector,
          isHead,
          isDBUser,
          isCrmUser,
          isM2User,
          isAdmin,
          phone
        } = userPayload;

        user = {
          ...user,
          id,
          avatar,
          email,
          fullName: `${lastName} ${firstName} ${middleName}`,
          isAgent,
          isDirector,
          isHead,
          isDBUser,
          isCrmUser,
          isM2User,
          isAdmin,
          phone
        };

        localStorage.setItem('user', JSON.stringify(user));
        state.user = user;
      }, [rejected]: (state, action) => {
        state.error = action.error;
      }
    };
  }

  function register() {
    const { pending, fulfilled, rejected } = extraActions.register;
    return {
      [pending]: (state) => {
        state.error = null;
      }, [fulfilled]: (state, action) => {
        const register = action.payload;
        register.phone = action.meta.arg.phone;

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('register', JSON.stringify(register));
        state.register = register;
      }, [rejected]: (state, action) => {
        state.error = action.error;
      }
    };
  }

  function getPersonalInfo() {
    const { fulfilled } = extraActions.getPersonalInfo;
    return {
      [fulfilled]: (state, action) => {
        const { result } = action.payload;
        state.personalInfo = {
          ...result.user,
          phone: result.formattedPhone,
          agency: result.agency,
          position: result.position
        };
      },
    };
  }

  function update() {
    const { fulfilled } = extraActions.update;
    return {
      [fulfilled]: (state, action) => {
        const personalInfo = action.meta.arg;
        personalInfo.birthdayDate = personalInfo.birthdayDate.toJSON();
        personalInfo.avatar = state.personalInfo.avatar;
        state.personalInfo = personalInfo;
      },
    };
  }
}
