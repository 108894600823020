import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { ReactComponent as Tie } from "assets/tie.svg";
import { ReactComponent as Calendar } from "assets/icon/calendar.svg";
import { ReactComponent as Deals } from "assets/icon/deals.svg";
import { ReactComponent as Home } from "assets/icon/home.svg";
import { ReactComponent as Report } from "assets/icon/report.svg";
import { ReactComponent as User } from "assets/icon/user.svg";
import { ReactComponent as Storage } from "assets/icon/storage.svg";
import { ReactComponent as M2Logo } from "assets/m2logo.svg";
import { ReactComponent as Gear } from "assets/setting.svg";
import MenuItem from "./MenuItem";
import { ReactComponent as LogoMini } from 'assets/logo-mini.svg';
import { useTranslation } from "react-i18next";
import React from 'react';
import { Box } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  [`& a.active`]: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Menu() {
  const { t } = useTranslation(["translation", "form"]);
  const { user } = useSelector(x => x.auth);

  return (<Box sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "newGrey.veryLight",
    width: "100%",
    height: "100vh"
  }}>
    <Box sx={{
      backgroundColor: "rgba(89, 200, 250, 0.08)",
      color: "black",
      display: "flex",
      height: { xs: "40px", xl: "48px" },
      marginBottom: { xs: "16px", xl: "20px" },
      padding: { xs: "12px", xl: "16px" },
      width: { xs: 'calc(100% - 24px)', xl: 'calc(100% - 32px)' }
    }}>
      <LogoMini />
    </Box>
    {
      (user.isDirector || user.isHead) && (<MenuItem
        to={"/crm/agents"}
        title={t('menuProduct0')}><Tie width={24} height={24} /></MenuItem>)
    }
    {
      (<MenuItem
        to={"/crm/clients"}
        title={t('clients')}><User width={24} height={24} /></MenuItem>)
    }
    {
      (<MenuItem
        to={"/crm/apartments"}
        title={t('menuProduct2')}><Home width={24} height={24} /></MenuItem>)
    }
    {
      (<MenuItem
        to={"/crm/calendar"}
        title={t('calendar')}><Calendar width={24} height={24} /></MenuItem>)
    }
    {
      (user.isDirector || user.isHead) && (<MenuItem
        to={"/crm/reports"}
        title={t('menuProduct4')}><Report width={24} height={24} /></MenuItem>)
    }
    {
      (user.isDirector || user.isHead) && (<MenuItem
        to={"/crm/deals"}
        title={t('menuProduct5')}><Deals width={24} height={24} /></MenuItem>)
    }
    {
      (user.isDirector || user.isHead) && (<MenuItem
        to={"/db"}
        title={t('database')}><Storage width={24} height={24} /></MenuItem>)
    }
    {
      (user.isDirector || user.isHead) && (<MenuItem
        to={"/m2"}
        title={t('database') + " М2"}><M2Logo width={24} height={24} /></MenuItem>)
    }
    <Box sx={{
      margin: "auto auto 0 auto",
    }}>
      <MenuItem
        to={"/user"}
        title={t('settings')}><Gear width={24} height={24} /></MenuItem>
    </Box>
  </Box>);
}
