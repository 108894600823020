import { Grid } from '@mui/material';
import React, { useRef, useState } from 'react';
import { CalendarWidget } from '../calendar';
import { ScrolledGrid } from '../ScrolledGrid';

export function Boards({ boards }) {

  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    const scrollContainer = scrollContainerRef.current;
    setIsDragging(true);
    setStartX(e.pageX - scrollContainer.offsetLeft);
    setScrollLeft(scrollContainer.scrollLeft);
  };

  const handleMouseMove = (e) => {

    if (e.target.closest('.isDraggingFalse')) {
      setIsDragging(false);
      return;
    }

    if (!isDragging) {
      return;
    }

    const scrollContainer = scrollContainerRef.current;
    const x = e.pageX - scrollContainer.offsetLeft;
    scrollContainer.scrollLeft = scrollLeft - (x - startX);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return <>
    <Grid
      item
      xs={9}
      sx={{
        height: { xs: "calc(100vh - 64px)", xl: "calc(100vh - 84px)" },
      }}
    >
      <ScrolledGrid

        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onDrag={handleMouseUp}
        onDrop={handleMouseUp}

        container
        columnSpacing={2.5}
        className="custom-scrollbar"
      >
        {boards}
      </ScrolledGrid>
    </Grid>
    <CalendarWidget />
  </>
}

