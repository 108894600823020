import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { agentApi } from "helpers";

const name = "agent";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports
export const agentActions = { ...slice.actions, ...extraActions };
export const agentReducer = slice.reducer;

// implementation
function createInitialState() {
  return {
    all: [], free: [],
  };
}

function createReducers() {
  return {};
}

function createExtraActions() {
  return {
    create: create(), all: all(), free: free(), update: update()
  };

  function create() {
    return createAsyncThunk(`${name}/create`, async (data) => await agentApi.create(data));
  }

  function all() {
    return createAsyncThunk(`${name}/all`, async () => await agentApi.all());
  }

  function free() {
    return createAsyncThunk(`${name}/free`, async () => await agentApi.free());
  }

  function update() {
    return createAsyncThunk(`${name}/update`, async (data) => await agentApi.update(data));
  }
}

function createExtraReducers() {
  return {
    ...createAgent(), ...free(), ...all(),
  };

  function createAgent() {
    const { pending, fulfilled, rejected } = extraActions.create;
    return {
      [pending]: (state) => {
        state.error = null;
      }, [fulfilled]: (state, action) => {
        const agent = action.meta.arg;
        agent.birthdayDate = agent.birthdayDate.toJSON();
        agent.created = agent.created.toJSON();
        agent.id = action.payload.id;
        state.all.push(agent);
      }, [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function all() {
    const { fulfilled } = extraActions.all;
    return {
      [fulfilled]: (state, action) => {
        action.payload.result.sort((a, b) => a.lastName.localeCompare(b.lastName));
        state.all = action.payload.result;
      },
    };
  }

  function free() {
    const { pending, fulfilled, rejected } = extraActions.free;
    return {
      [pending]: (state) => {
        state.error = null;
      }, [fulfilled]: (state, action) => {
        action.payload.result.sort((a, b) => a.lastName.localeCompare(b.lastName));
        state.free = action.payload.result;
      }, [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }
}
