import ApiDefault from "./ApiDefault";

const PATH = 'crm/calendar';

export default class Calendar extends ApiDefault {
  async create(data) {
    return this.post(PATH, data);
  }

  async all() {
    return this.get(PATH);
  }

  async client(id) {
    return this.get(PATH + "/client/" + id);
  }

  async delete(id) {
    return this.remove(PATH + '/' + id)
  }
}
