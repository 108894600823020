import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid'
import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { calendarActions } from "store";
import "./Calendar.css"
import { StyledCalendar } from "./CalendarWidget";

export function Calendar({ events, currentDate, setEvent, setOpen }) {
  const { user } = useSelector(x => x.auth);
  const dispatch = useDispatch();

  const handleEventClick = ({ event }) => {
    const { title, start, end, id } = event;
    setEvent({ dateFrom: start, dateTo: end, title, id });
    setOpen(true)
  }

  const handleSelect = (info) => {
    const { start, end } = info;
    setEvent({ dateFrom: start, dateTo: end, title: "" });
    setOpen(true)
  }

  const handleEventChange = ({ event }) => {
    const { title, start, end, id } = event;
    dispatch(calendarActions.create({ dateFrom: start, dateTo: end, title, id }))
  }

  return <>
    {generateDateRange(currentDate, 5).map((currentDate, index) =>
      <CalendarDay
        key={index}
        isCurrentDay={index === 1}
        user={user}
        currentDate={currentDate}
        events={events}
        handleEventClick={handleEventClick}
        handleSelect={handleSelect}
        handleEventChange={handleEventChange}
      />
    )}
  </>
}

const CalendarDay = ({ currentDate, events, user, isCurrentDay, handleEventClick, handleSelect, handleEventChange, handleDrop }) => {
  const { i18n } = useTranslation();
  const calendarRef = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      api.gotoDate(currentDate);
    }
  }, [currentDate]);

  // useEffect(() => {
  //   if (isCurrentDay) {
  //     ref.current.scrollIntoView({ block: 'nearest', inline: 'start' });
  //   }
  // }, [ref, currentDate]);

  return <Box
    className="calendar widget"
    ref={ref}
    sx={{
      backgroundColor: "newGrey.veryLight",
      borderRadius: "4px",
      boxShadow: "0px 0px 10px 2px rgba(1, 0, 34, 0.05)",
      p: 2.5,
      pb: 0,
      height: "calc(100% - 40px)",
      minWidth: { xs: "284px", xl: "355px" },
      mr: 1.25
    }}
  >
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2 }}>
      <Box
        sx={{ p: "6px", borderRadius: "30px", backgroundColor: "newGrey.medium2", width: "60%", textAlign: "center" }}>
        <Typography variant="text" sx={{ color: "dark", fontSize: "14px" }}>{formatDate(currentDate, i18n)}</Typography>
      </Box>
    </Box>
    <StyledCalendar>
      <FullCalendar
        initialDate={currentDate}
        contentHeight="100"
        droppable={user.isCrmUser}
        dayCount={1}
        eventReceive={handleEventChange}
        editable={user.isCrmUser}
        events={events}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        eventDrop={handleEventChange}
        eventResize={handleEventChange}
        initialView='timeGridDay'
        height="100%"
        headerToolbar={false}
        locale={i18n.language === 'ru' ? 'ru' : 'uk'}
        plugins={[timeGridPlugin, interactionPlugin]}
        ref={calendarRef}
        select={handleSelect}
        selectable={user.isCrmUser}
        slotMinTime="07:00:00"
        slotLabelFormat={{
          hour: 'numeric',
          minute: 'numeric',
        }}
        weekends={true}
      />
    </StyledCalendar>
  </Box>
}

const renderEventContent = (eventInfo) => {
  return (
    <Box sx={
      {
        color: "text.white",
        display: "flex",
        fontSize: "14px",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        lineHeight: "22px",
        overflow: "hidden"
      }}>
      <Box sx={{fontWeight: 500}}>{eventInfo.timeText}</Box>
      <Box>{eventInfo.event.title}</Box>
    </Box>
  )
}

const formatDate = (date, i18n) => {
  const locale = i18n.language === 'ru' ? 'ru-RU' : 'uk-UK';
  const options = { weekday: 'short', day: 'numeric', month: 'long' };
  return new Intl.DateTimeFormat(locale, options).format(date);
};

const generateDateRange = (startDate, n) => {
  const start = new Date(startDate);
  start.setDate(start.getDate() - 1);

  const dateArray = [new Date(start)];

  for (let i = 1; i <= n; i++) {
    const newDate = new Date(start);
    newDate.setDate(start.getDate() + i);
    dateArray.push(newDate);
  }

  return dateArray;
}